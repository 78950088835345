<template>
  <div class="wp-body" style="padding-bottom:30px;" v-if="!loading" v-loading.fullscreen.lock="loading"
    :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <header class="wp-header">
      <div class="grid grid-cols-12 gap-3 flex-1">
        <div  v-if="['admin', 'ministry'].includes(me.role.name)" class="col-span-2">
          <el-select :placeholder="$t('region')" class="w-full" v-model="region"  @change="regionChanged(); __GET();" clearable>
            <el-option v-for="item in regions" :key="item.id" :label="item[`name_${$i18n.locale}`]?item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.shared_id">
            </el-option>
          </el-select>
        </div>
        <div v-if="['admin', 'ministry'].includes(me.role.name)" class="col-span-2">
          <el-select :placeholder="$t('district')" class="w-full"  v-model="district" @change="ChangeDistrict(), __GET()" :disabled="!region" clearable>
            <el-option v-for="item in districts" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div v-if="['admin', 'ministry'].includes(me.role.name)" class="col-span-2">
          <el-select :placeholder="$t('building_category')" class="w-full"  @change="__GET()" v-model="buildinggroup" clearable>
            <el-option v-for="item in building_group" :key="item.id" :label="item[`name_${$i18n.locale}`]?item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="col-span-2">
          <el-input class="w-full"  :placeholder="$t('search')" prefix-icon="el-icon-search" v-model="search" @keyup.enter.native="__GET()" clearable>
            </el-input>
        </div>
        <div class="col-span-4 flex items-center gap-2">
          <button class="button dark-blue" @click="fetchOptions()">{{ $t('advanced_filter') }}</button>
          <!-- <button v-if="['admin'].includes(role)"  type="button" class="button dark-blue" :disabled="loading_generate" @click="generate_building_list()">
              <i class="el-icon-download" v-if="!loading_generate"></i>
              <i class="el-icon-loading" v-else></i>
              {{ $t("download") }}
          </button> -->
        </div> 
      </div>  
    </header>
    <el-table :data="buildsList" class="mt-5 fs-13" style="width: 99%; margin-bottom: 20px" row-key="id"
      default-expand-all :row-class-name="tableRowClassName">
      <el-table-column label="№" width="100px" align="center">
        <template slot-scope="scope">
          {{ (current_page - 1) * item_count + (scope.$index + 1) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('building_name')" align="center" width="150" sortable>
        <template slot-scope="scope">
          <button @click="routeTo(scope.row, 0)" class="table-link-button router-link">
            {{ scope.row.name_cyr ? scope.row.name_cyr : "***" }}
            <i class="el-icon-top-right"></i>
          </button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('building_catagory')" prop="building_group.name_cyr" align="center" sortable>
      </el-table-column>
      <el-table-column prop="cadastral_number" :label="$t('cadaster_number')" width="200" align="center" sortable>
      </el-table-column>
      <el-table-column width="95px" class="p0" prop="finalconclusion" :label="$t('finalconclusion')" align="center"
        sortable>
        <template slot-scope="scope">
          <p :class="parseInt(scope.row.finalconclusion) > 2
              ? parseInt(scope.row.finalconclusion) == 3
                ? 'c-yellow'
                : 'c-red'
              : 'c-green'
            ">
            {{ scope.row.finalconclusion }}
          </p>
        </template>
      </el-table-column>
      <el-table-column :label="$t('created_at')" width="120" prop="created_at" align="center" sortable>
      </el-table-column>
      <el-table-column :label="$t('year_construction')" width="100" prop="year_construction" align="center" sortable>
      </el-table-column>
      <el-table-column v-if="$route.path !== '/unattested-building-list/new'" :label="$t('investment_project')"
        prop="is_invest" align="center" width="130px">
        <template slot-scope="scope">
          <InvestmentSwitch v-model="scope.row.is_invest" :id="scope.row.id" :disabled="role != 'operator'" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('created_by')" align="center" sortable>
        <template slot-scope="scope">
          <div class="table-link-button" @click="UserModal(scope.row.created_by)">
            {{
              scope.row.created_by != null &&
                scope.row.created_by.profile != null
                ? scope.row.created_by.profile.name +
                " " +
                scope.row.created_by.profile.surname
                : "-"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('updated_by')" align="center" sortable>
        <template slot-scope="scope">
          <div class="table-link-button" @click="UserModal(scope.row.updated_by)">
            {{
              scope.row.updated_by != null &&
                scope.row.updated_by.profile != null
                ? scope.row.updated_by.profile.name +
                " " +
                scope.row.updated_by.profile.surname
                : "-"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="role == 'admin'
          ? $t('passport') + ' / ' + $t('statistic')
          : $t('statistic')
        " width="180" align="center">
        <template slot-scope="scope">
          <div class="flex justify-center">
            <div v-if="role == 'admin'">
              <el-button @click="GeneratePasport(scope.row.id)" small class="el-button-download">
                <i class="el-icon-s-order"></i>
              </el-button>
            </div>
            <el-button class="ml-2  el-button-download" size="small"
              @click="DownloadExel(scope.row.id, scope.row.name_cyr)">
              <i class="el-icon-download"></i>
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100px" align="center" :label="role == 'operator' ? $t('change') + '/' + $t('delete') : $t('change')
        " v-if="
          role !== 'org_admin' &&
          role !== 'admin' &&
          role !== 'ministry_admin' &&
          !(role == 'operator' && $route.params.type == 'new')
        ">
        <template slot-scope="scope">
          <div class="d-flex justify-center">
            <el-button @click="routeTo(scope.row, 1)" size="small" class="el-icon-edit el-button-download"></el-button>
            <el-button v-if="role == 'operator' && false" @click="deleteBuilding(scope.row.id)" size="small"
              class="el-icon-delete el-button-download danger"></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex items-center justify-between">
      <div>
        <p class="unnatested-count">
          {{ $t('unattested_count') }}: {{ unattestedCount }}
        </p>
      </div>
      <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]"
        :page-size="5" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page1"
        :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count"
        :total="null">
      </el-pagination>
    </div>
    <el-drawer :title="$t('building_passport')" :visible.sync="drawer" direction="rtl">
      <div>
        <div class="pdf">
          <vue-pdf ref="print" :src="pdf"></vue-pdf>
          <div class="loading-pdf" v-show="loadDawer">
            <i class="el-icon-loading"></i>
          </div>
        </div>
        <div class="w-full pdf-control">
          <button class="button dark-yellow" @click="downloadFile(pdf)">
            <i class="el-icon-download mr-2"></i> {{ $t("download") }}
          </button>
          <a :href="pdf" target="_blank">
            <button class="ml-3 button dark-blue">
              <i class="el-icon-view mr-2"></i> {{ $t("see") }}
            </button>
          </a>
        </div>
      </div>
    </el-drawer>
    <div class="modal fs-14" v-if="usermodal">
      <div class="modal-cover"></div>
      <div class="modal-item" style="max-width:500px;">
        <div class="flex justify-between">
          <p class="modal-title ">{{ $t("user_date") }}:</p>
        </div>
        <el-row :gutter="24">
          <el-col :md="12" class="fw-600 mb-2">{{ $t("first_name") }}:</el-col>
          <el-col :md="12" class="mb-2">{{ userData.profile.name }}</el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="12" class="fw-600 mb-2">{{ $t("last_name") }}:</el-col>
          <el-col :md="12" class="mb-2">{{ userData.profile.surname }}</el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="12" class="fw-600 mb-2">{{ $t("mid_name") }}:</el-col>
          <el-col :md="12" class="mb-2">{{
            userData.profile.middlename
            }}</el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="12" class="fw-600 mb-2">{{ $t("phone_munber") }}:</el-col>
          <el-col :md="12" class="mb-2">{{
            userData.profile.phone_number
            }}</el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="12" class="fw-600 mb-2">{{ $t("user_email") }}:</el-col>
          <el-col :md="12" class="mb-2">{{
            userData.profile.email ? userData.profile.email : "-"
            }}</el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="12" class="fw-600 mb-2">{{ $t("organisation_name") }}:</el-col>
          <el-col :md="12" class="mb-2">{{
            userData.organization && userData.organization.name_cyr
              ? userData.organization.name_cyr
              : "-"
          }}</el-col>
        </el-row>

        <div class="w-full modal-inputes flex justify-end">
          <button @click="usermodal = false" style="padding:15px 15px;">
            <i class="el-icon-close mr-2"></i> {{ $t("close") }}
          </button>
        </div>
      </div>
    </div>
    <!-- FILTERS -->
    <el-dialog class="advanced-filter-dialog" :title="$t('buildings')" :visible.sync="dialogVisible" width="55%"
              style="margin-left: 10px;">
              <div class="flex advanced-filter-input flex-wrap justify-center gap-8">
                <div v-for="(placeholder, index) in placeholders" :key="index">
                  <div class="flex flex-col">
                    <label class="block font-medium text-sm mb-2">
                      {{ $t(placeholder.title) }}
                    </label>

                    <el-date-picker v-if="index === placeholders.length - 2"
                      v-model="selectedOptions[placeholder.value]" :placeholder="$t('year_of_construction')" type="year"
                      value-format="yyyy" class="advanced-filter-select" filterable></el-date-picker>

                    <el-select v-else-if="index === placeholders.length - 1"
                      v-model="selectedOptions[placeholder.value]" class="advanced-filter-select"
                      :placeholder="$t('floor_count')" clearable filterable>
                      <el-option v-for="num in 100" :key="num" :label="num.toString()" :value="num"></el-option>
                    </el-select>
                    <el-select v-else v-model="selectedOptions[placeholder.value]" class="advanced-filter-select"
                      :placeholder="$t(placeholder.title)" clearable>
                      <el-option v-for="item in options[index]" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="advanced-filter-btn flex w-full justify-end mt-8">
                <div slot="footer" class="dialog-footer flex justify-end gap-2">
                  <button class="button black" @click="dialogVisible = false">
                    {{ $t('cancel') }}
                  </button>
                  <button class="button dark-blue" type="primary" @click="__GET(), dialogVisible = false">
                    {{ $t('approve_btn') }}
                  </button>
                </div>
              </div>
              
    </el-dialog>
  </div>
  <ScletonPage v-else />
</template>

<script>

import ScletonPage from "./../components/Scletons/Page.vue";
import InvestmentSwitch from "@/components/InvestmentSwitch";
export default {
  components: {
    ScletonPage,
    InvestmentSwitch,
  },
  data() {
    return {
      pdf: "",
      namePdf: "",
      loadDawer: false,
      districts: [],
      drawer: false,
      input2: null,
      page_count: 0,
      buildsList: [],
      region: "",
      district: "",
      buildinggroup: "",
      current_district: [],
      objInfo: {},
      search: "",
      item_count: 10,
      loading: false,
      current_page: 1,
      current_page1: 1,
      buildingtype: "",
      usermodal: false,
      unattestedCount: 0,
      userData: {
        organization: {},
        profile: {},
      },
      loading_generate: false,
      dialogVisible: false,
      selectedOptions: {},
      placeholders: [
        {
          value: "constructivesolution",
          title: 'constructive_solution',
        },
        {
          value: "plannedshape",
          title: "plannedshape",
        },
        {
          value: "buildingseismicity",
          title: "transport_seismicity_construction_site"
        },
        {
          value: "reconstructiondurability",
          title: "building_reconstruction",
        },
        {
          value: "dailycondition",
          title: "passport_dailycondition",
        },
        {
          value: "dividedintosections",
          title:
            "divided_into_sections_input",
        },
        {
          value: "loadbearing",
          title:
            "loadbearingstatus_input",
        },
        {
          value: "earthquakecondition",
          title:
            "earthquakecondition_input",
        },
        {
          value: "year_construction",
          title: "year_of_construction",
        },
        {
          value: "numberfloors",
          title: "floor_count",
        },
      ],
      options: [],
      dictionary_for_fill: [],
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        if (val?.query?.status) {
          this.__GET();
        }
      },
    },
    "$route.params.type"() {
      if (this.$route.params.type == undefined) {
        if (
          this.me.role.name == "ministry" ||
          this.me.role.name == "ministry_admin"
        ) {
          this.$router.push("/unattested-building-list/new").catch((e) => {
            if (
              e.name !== "NavigationDuplicated" &&
              !e.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(e);
            }
          });
        } else {
          if (this.$route.path == "/unattested-building-list/") {
            this.$router.push("/unattested-building-list/exg").catch((e) => {
              if (
                e.name !== "NavigationDuplicated" &&
                !e.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.log(e);
              }
            });
          } else if (this.$route.path == "/unattested-building-list/") {
            this.$router.push("/unattested-building-list/exg").catch((e) => {
              if (
                e.name !== "NavigationDuplicated" &&
                !e.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.log(e);
              }
            });
          }
        }
      } else {
        this.__GET();
      }
    },
  },
  computed: {
    dictionary() {
      return this.$store.state.dictionary;
    },
    baseURL() {
      return axios.defaults.baseURL;
    },
    buildType() {
      let a = {
        exg: "Мавжуд",
        new: "Янги",
      };

      return a[this.$route.params.type];
    },
    me() {
      return this.$store.state.me;
    },
    role() {
      return this.$store.state.role;
    },
    regions() {
      return this.$store.state.dictionary.region;
    },
    building_group() {
      return this.$store.state.dictionary.buildinggroup;
    },
  },
  created() {
    this.loading = true;
    if (this.$route.params.type == undefined) {
      if (
        this.me.role.name == "ministry" ||
        this.me.role.name == "ministry_admin"
      ) {
        this.$router.push("/unattested-building-list/new").catch((e) => {
          if (
            e.name !== "NavigationDuplicated" &&
            !e.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(e);
          }
        });
      } else {
        if (this.$route.path == "/unattested-building-list/") {
          this.$router.push("/unattested-building-list/exg").catch((e) => {
            if (
              e.name !== "NavigationDuplicated" &&
              !e.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(e);
            }
          });
        } else if (this.$route.path == "/unattested-building-list/") {
          this.$router.push("/unattested-building-list/exg").catch((e) => {
            if (
              e.name !== "NavigationDuplicated" &&
              !e.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(e);
            }
          });
        }
      }
    } else {
      this.current_page = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.search = this.$route.query.search ? this.$route.query.search : "";
      this.region = this.$route.query.region
        ? parseInt(this.$route.query.region)
        : "";
      this.district = this.$route.query.district
        ? parseInt(this.$route.query.district)
        : "";
      this.buildinggroup = this.$route.query.buildinggroup
        ? parseInt(this.$route.query.buildinggroup)
        : "";
      this.item_count = this.$route.query.item_count
        ? parseInt(this.$route.query.item_count)
        : 10;
      if (this.region != "") {
        this.getDistrict();
      }
      this.__GET();
    }
  },
  mounted() {

  },
  methods: {
    fetchOptions() {
      this.dialogVisible = true

      const data = [
        "constructivesolution",
        "plannedshape",
        "buildingseismicity",
        "reconstructiondurability",
        "dailycondition",
        "dividedintosections",
        "loadbearing",
        "earthquakecondition",
      ]
      data.forEach((option, _) => {
        const arr = []
        this.dictionary[option].forEach(elem => {
          arr.push({
            label: elem.name_cyr || elem.number,
            value: elem.id
          })
        })
        this.options.push(arr)
      })
    },
    GeneratePasport(id) {
      this.loading = true;
      axios
        .post(`/building/specification/${id}/generate/`)
        .then((res) => {
          if (res.data.success) {
            this.drawer = true;
            this.loadDawer = true;
            this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link;
          }
        })
        .catch((e) => { })
        .finally(() => {
          this.loading = false;

          this.loadDawer = false;
        });
    },
    tableRowClassName({ row }) {
      if (row.finalconclusion > 2) {
        return "warning-row";
      }
      if (row.finalconclusion < 3) {
        return "success-row";
      }
    },
    paginate(val) {
      this.current_page = val;
      this.__GET();
    },
    paginateSize(val) {
      this.item_count = val;
      this.__GET();
    },
    __GET() {
      let type = this.$route.params.type.toUpperCase();
      let offset = this.item_count * (this.current_page - 1);
      const status = this.$route.query.status || "new";

      const query = {}

      for (const option in this.selectedOptions) {
        if (this.selectedOptions[option] !== "") {
          query[option] = this.selectedOptions[option]
        }
      }

      if (
        this.search != "" ||
        this.region != "" ||
        this.district != "" ||
        this.buildinggroup != "" ||
        this.item_count != 10
      ) {
        if (this.$route.path == "/unattested-building-list/") {
          const { type } = this.$route.params;
          const {
            current_page,
            region,
            district,
            buildinggroup,
            item_count,
            search,
          } = this;
          this.$router
            .push({
              path: `/unattested-building-list/${type}`,
              query: {
                page: current_page,
                status: status,
                region: region,
                district: district,
                buildinggroup: buildinggroup,
                item_count: item_count,
                search: search,
                ...query
              },
            })
            .catch((e) => {
              if (
                e.name !== "NavigationDuplicated" &&
                !e.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.log(e);
              }
            });
        } else {
          const { type } = this.$route.params;
          const {
            current_page,
            region,
            district,
            buildinggroup,
            item_count,
            search,
          } = this;
          this.$router
            .push({
              path: `/unattested-building-list/${type}`,
              query: {
                page: current_page,
                status,
                region,
                district,
                buildinggroup,
                item_count,
                search,
                ...query
              },
            })
            .catch((e) => {
              if (
                e.name !== "NavigationDuplicated" &&
                !e.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.log(e);
              }
            });
        }
      } else {
        if (
          this.$route.path ==
          `/unattested-building-list/${this.$route.params.type}`
        ) {
          const { type } = this.$route.params;
          const { current_page } = this;
          this.$router
            .push({
              path: `/unattested-building-list/${type}`,
              query: {
                page: current_page,
                status,
                ...query
              },
            })
            .catch((e) => {
              if (
                e.name !== "NavigationDuplicated" &&
                !e.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.log(e);
              }
            });
        } else {
          const { type } = this.$route.params;
          const { current_page } = this;
          this.$router
            .push({
              path: `/unattested-building-list/${type}`,
              query: {
                page: current_page,
                ...query
              },
            })
            .catch((e) => {
              if (
                e.name !== "NavigationDuplicated" &&
                !e.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.log(e);
              }
            });
        }
      }

      if (
        this.role == "admin" &&
        this.$route.path ==
        `/unattested-building-list/${this.$route.params.type}`
      ) {
        const url = `/building/specificationlist/`;
        const params = {
          list: "selectionforadmin",
          search: this.search,
          region__shared_id: this.region,
          district: this.district,
          building_group: this.buildinggroup,
          limit: this.item_count,
          offset: offset,
          status: this.$route.query.status || "",
          ...query
        };

        axios
          .get(url, { params })
          .then((response) => {
            this.unattestedCount = response.data.count
            this.buildsList = response.data.results;
            this.page_count = Math.ceil(response.data.count / this.item_count);
            this.current_page1 = this.$route.query.page
              ? parseInt(this.$route.query.page)
              : parseInt(this.$route.query.page);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (
        this.$route.path ==
        `/unattested-building-list/exg?page=${this.currentPage}&status=${status}`
      ) {
        const url = `/building/specificationlist/`;
        const params = {
          list: "selectionforadmin",
          buildingtype: type,
          search: this.search,
          region__shared_id: this.region,
          district: this.district,
          building_group: this.buildinggroup,
          limit: this.item_count,
          offset: offset,
          status: this.$route.query.status || "",
          ...query
        };


        axios
          .get(url, { params })
          .then((response) => {
            this.unattestedCount = response.data.count
            this.buildsList = response.data.results;
            this.page_count = Math.ceil(response.data.count / this.item_count);
            this.current_page1 = this.$route.query.page
              ? parseInt(this.$route.query.page)
              : parseInt(this.$route.query.page);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.$route.path == "/unattested-building-list/new") {
        const {
          type,
          search,
          region,
          district,
          buildinggroup,
          item_count,
          offset,
        } = this;
        axios
          .get("/building/specificationlist/", {
            params: {
              list: "selectionforadmin",
              buildingtype: type,
              search: search,
              region__shared_id: region,
              district: district,
              building_group: buildinggroup,
              limit: item_count,
              offset: offset,
              status: this.$route.query.status || "",
              ...query
            },
          })
          .then((response) => {
            this.unattestedCount = response.data.count
            this.buildsList = response.data.results;
            this.page_count = Math.ceil(response.data.count / this.item_count);
            this.current_page1 = this.$route.query.page
              ? parseInt(this.$route.query.page)
              : parseInt(this.$route.query.page);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        const {
          type,
          search,
          region,
          district,
          buildinggroup,
          item_count,
          offset,
        } = this;
        axios
          .get("/building/specificationlist/", {
            params: {
              list: "selection",
              buildingtype: type,
              search: search,
              region__shared_id: region,
              district: district,
              building_group: buildinggroup,
              limit: item_count,
              offset: offset,
              status: this.$route.query.status || "",
              ...query
            },
          })
          .then((response) => {
            this.unattestedCount = response.data.count
            this.buildsList = response.data.results;
            this.page_count = Math.ceil(response.data.count / this.item_count);
            this.current_page1 = this.$route.query.page
              ? parseInt(this.$route.query.page)
              : parseInt(this.$route.query.page);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    routeTo(item, a) {
      const label = {
        'EXG': 'exist',
        'NEW': 'new',
        'exg': 'exist',
        'new': 'new',
      };

      const type = label[item.buildingtype] || 'exist';
      this.$router
        .push({
          path: `/${type}-build/${item.id}/${a}`,
          params: {
            id: this.$route.params.id,
          },
          query: {
            id: item.id,
            status: this.$route.query.status
          },
        })

    },
    regionChanged() {
      this.current_page = 1;
      this.current_page1 = 1;
      if (
        this.search != "" ||
        this.region != "" ||
        this.district != "" ||
        this.buildinggroup != "" ||
        this.item_count != 10
      ) {
        const { type } = this.$route.params;
        const {
          current_page,
          region,
          district,
          buildinggroup,
          item_count,
          search,
        } = this;
        this.$router
          .push({
            path: `/unattested-building-list/${type}`,
            query: {
              page: current_page,
              region,
              district,
              buildinggroup,
              item_count,
              search,
            },
          })
          .catch((e) => {
            if (
              e.name !== "NavigationDuplicated" &&
              !e.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(e);
            }
          });
      } else {
        const { type } = this.$route.params;
        const { current_page } = this;
        this.$router
          .push({
            path: `/unattested-building-list/${type}`,
            query: { page: current_page },
          })
          .catch((e) => {
            if (
              e.name !== "NavigationDuplicated" &&
              !e.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(e);
            }
          });
      }
      this.districts = [];
      this.district = "";
      this.getDistrict();
    },
    getDistrict() {
      this.loading = true;
      axios
        .get(`/dictionary/district/?region__shared_id=${this.region}`)
        .then((response) => {
          this.districts = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    ChangeDistrict() {
      this.current_page = 1;
      this.current_page1 = 1;
      const {
        search,
        region,
        district,
        buildinggroup,
        item_count,
        current_page,
      } = this;
      const { type } = this.$route.params;
      if (search || region || district || buildinggroup || item_count !== 10) {
        this.$router.push({
          path: `/unattested-building-list/${type}`,
          query: {
            page: current_page,
            region,
            district,
            buildinggroup,
            item_count,
            search,
          },
        });
      } else {
        this.$router.push({
          path: `/unattested-building-list/${type}`,
          query: {
            page: current_page,
          },
        });
      }
    },
    DownloadExel(id, name) {
      axios({
        url: `/building/generate/${id}/building/`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${name}-Bino-${id}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadFile(file) {
      fetch(file)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = "PasportBino" || "file.json";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    },
    UserModal(a) {
      if (a != null && a.profile != null) {
        this.userData = a;
        this.usermodal = true;
      }
    },
    deleteBuilding(id) {
      return this.$confirm(`Сиз ростдан ҳам ушбу бинони ўчирмоқчимисиз?`, {
        confirmButtonText: "Ҳа",
        cancelButtonText: "Йўқ",
      }).then(() => {
        axios
          .delete(`/building/buildingdestroy/${id}/`)
          .then((response) => {
            this.$swal.fire({
              icon: "success",
              title: "Қониқарли. Бинони учирилди",
              timer: 3000,
            });
            this.__GET();
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              title: "Бинони учиришда хатолик",
              timer: 3000,
            });
          });
      });
    },
    generate_building_list() {
      this.loading_generate = true;
      let type = this.$route.params.type.toUpperCase();
      let offset = this.item_count * (this.current_page - 1);
      const { search, region, district, buildinggroup, item_count } = this;
      const params = {
        buildingtype: type,
        search: search,
        region__shared_id: region,
        district: district,
        building_group: buildinggroup,
        limit: item_count,
        offset: offset,
      };

      axios({
        url: "/building/generate_building_info/",
        method: "GET",
        params: params,
        responseType: "blob",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Bino-1.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .finally(() => {
          this.loading_generate = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-header {
  margin-bottom: 20px;
}

.h-full {
  height: 100%;
}

.df {
  display: flex;
}

.jcsb {
  justify-content: space-between;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

.unnatested-count {
  color: #004787;
  font-weight: 600;
  font-size: 20px;
}
</style>
