<template>
<div class="obj-list  ofh">
    <div class="top-header" v-if="header">
        <div>
            <el-skeleton style="width: 240px" animated>
                <template slot="template">
                    <div class="flex items-center">
                        <el-skeleton-item variant="h1" style="width: 100%" />
                    </div>
                </template>
            </el-skeleton>
        </div>
        <div class="flex">
            <el-skeleton style="width: 100px" class="mr-2" animated>
                <template slot="template">
                    <div class="flex items-center">
                        <el-skeleton-item variant="p" style="width: 100%" />
                    </div>
                </template>
            </el-skeleton>
            <el-skeleton style="width: 100px" animated>
                <template slot="template">
                    <div class="flex items-center">
                        <el-skeleton-item variant="p" style="width: 100%" />
                    </div>
                </template>
            </el-skeleton>
        </div>
    </div>
    <div class="wp-body w-full">
        <header class="wp-header w-full noborder">
            <div class="flex w-full justify-between items-center">
                <div style="min-width:300px;">
                    <el-skeleton style="width: 300px" animated>
                        <template slot="template">
                            <div class="flex items-center">
                                <el-skeleton-item variant="p" style="width: 100%" />
                            </div>
                        </template>
                    </el-skeleton>
                </div>
                <div class="filters w-full flex bb justify-end">
                    <div  class="flex items-center mr-2">
                        <el-skeleton style="width: 150px" animated>
                            <template slot="template">
                                <div class="flex items-center">
                                    <el-skeleton-item variant="p" style="width: 100%" />
                                </div>
                            </template>
                        </el-skeleton>
                    </div>
                    <div   class="flex items-center mr-2">
                        <el-skeleton style="width: 150px" animated>
                            <template slot="template">
                                <div class="flex items-center">
                                    <el-skeleton-item variant="p" style="width: 100%" />
                                </div>
                            </template>
                        </el-skeleton>
                    </div>
                    <div   class="flex items-center mr-2">
                        <el-skeleton style="width: 150px" animated>
                            <template slot="template">
                                <div class="flex items-center">
                                    <el-skeleton-item variant="p" style="width: 100%" />
                                </div>
                            </template>
                        </el-skeleton>
                    </div>
                </div>
            </div>
        </header>
        <div class="table-scleton w-full">
             <table class="w-full">    
                <tr v-for="a in 15" :key="a">
                    <td v-for="i in 6" :key="i">
                        <el-skeleton style="width: 100%" animated>
                        <template slot="template">
                            <div class="flex items-center">
                                <el-skeleton-item variant="p" style="width: 100%" />
                            </div>
                        </template>
                    </el-skeleton>
                    </td> 
                </tr>
            </table>
            <div class="flex justify-end mt-5" >
                <el-skeleton style="width: 20px" animated v-for="i in 4" :key="i" class="mr-2">
                        <template slot="template">
                            <div class="flex items-center">
                                <el-skeleton-item variant="p" style="width: 100%" />
                            </div>
                        </template>
                    </el-skeleton>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props:['header']
};
</script>

<style lang="scss" scoped>
.top-header {
    width: 100%;
    padding: 20px; 
    border-radius: 16px;
    background: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-header h1 {
    font-weight: 700;
    font-size: 25px;
    color: #2f525a !important;
}

.title-second-header {
    font-size: 18px;
    font-weight: 600;
    color: #345a63 !important;
}




.table-scleton{
    table,td{border-collapse: collapse;}
    table{width: 100%;}
    td{padding: 20px; }
    tr{
            border-bottom: 1px solid #ebeef5;
    }
}
</style>
