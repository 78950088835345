<template>
    <div class="obj-list wrapper">
        <div class="header-container mb-8">
            <router-link :to="getStatusLink('new')" class="header-item" active-class="header-item-active" exact>
                <div class="header-radio-label"></div>
                {{ $t('unattested_new') }}
                <span class="building-count">{{ buildingCount.new || 0 }}</span>
            </router-link>
            <router-link :to="getStatusLink('review')" class="header-item" active-class="header-item-active" exact>
                <div class="header-radio-label"></div>
                {{ $t('unattested_approved') }}
                <span class="building-count">{{ buildingCount.review || 0 }}</span>
            </router-link>
            <router-link :to="getStatusLink('rejected')" class="header-item" active-class="header-item-active" exact>
                <div class="header-radio-label"></div>
                {{ $t('unattested_rejected') }}
                <span class="building-count">{{ buildingCount.rejected || 0 }}</span>
            </router-link>
        </div>
        <Unattested />
    </div>
</template>

<script>
import Unattested from '@/components/UnattestesBuilding.vue'
export default {
    components: { Unattested },
    data() {
        return {
            buildingCount: []
        };
    },
    watch: {
        '$route': {
            deep: true,
            handler() {
                this.getBuildingCount();
            }
        }
    },
    computed: {
        role() {
            return this.$store.state.role;
        },
    },
    mounted() {
        this.getBuildingCount()
    },
    methods: {
        getStatusLink(status) {
            const currentQuery = { ...this.$route.query, status: status };
            return { path: this.$route.path, query: currentQuery };
        },
        getBuildingCount() {
            axios.get(`building/specificationscount/`)
                .then((res) => {
                    this.buildingCount = res.data
                })
        }
    },
};
</script>
<style lang="scss">
.building-count {
    margin-left: 10px;
    font-size: 12px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #000;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

 
 
</style>
